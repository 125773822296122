import { Button, Row, Typography } from "antd";
import { Header } from "antd/es/layout/layout";
import { Dispatch, SetStateAction } from "react";
import { API_ENDPOINTS, axiosApiInstance } from "../../api";
import {
  getTokensFromLocalStorage,
  removeTokensFromLocalStorage,
} from "../../utils";

const { Title } = Typography;

export const AdminHeader: React.FC<{
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}> = ({ setLoggedIn }) => {
  const handleLogout = async () => {
    const tokens = getTokensFromLocalStorage();
    if (tokens) {
      const { status } = await axiosApiInstance.post(API_ENDPOINTS.logout, {
        refreshToken: tokens,
      });
      if (status === 201) {
        setLoggedIn(false);
        removeTokensFromLocalStorage();
      }
    }
  };
  return (
    <Header
      style={{ alignItems: "center", display: "flex", padding: 20 }}
      className="header"
    >
      <Row
        style={{
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
        }}
      >
        <Title style={{ color: "#fff", margin: 0 }} level={4}>
          Columbus Admin
        </Title>

        <Button onClick={handleLogout} danger type="primary">
          Logout
        </Button>
      </Row>
    </Header>
  );
};
