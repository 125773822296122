import { SendOutlined } from "@ant-design/icons";
import { Button, Card, Layout, Pagination, Row, Table, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { ComposeMessageModal } from "../../components/ComposeMessageModal";
import { AdminHeader } from "../../components/Header";
import { useNotificationsApi } from "../../hooks/useNotificationsApi";
import { columns } from "./columns";

const { Text } = Typography;

export const DashboardScreen: React.FC<{
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}> = ({ setLoggedIn }) => {
  const { isLoading, notifications, setPage, setLimit } = useNotificationsApi();
  const [isComposeMessageModalOpened, setComposeMessageModalOpened] =
    useState(false);

  const handleToggleModal = () =>
    setComposeMessageModalOpened(!isComposeMessageModalOpened);

  const handlePaginationChange = (data: any) => {
    setPage(data);
  };

  return (
    <Layout>
      <AdminHeader setLoggedIn={setLoggedIn} />
      <Layout>
        <Layout style={{ padding: "24px", height: "100vh" }}>
          <Card>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Title style={{ margin: 0 }} level={4}>
                Push-notification list
              </Title>

              <Button onClick={handleToggleModal} type="primary">
                <SendOutlined />
                Compose New Message
              </Button>
            </Row>
            <Table
              pagination={{
                onChange: handlePaginationChange,
                total: notifications?.meta.totalItems,
                defaultPageSize: notifications?.meta.itemsPerPage,
                showSizeChanger: true,
                onShowSizeChange: (_, limit) => setLimit(limit),
              }}
              loading={isLoading}
              sortDirections={["ascend"]}
              bordered
              expandable={{
                expandedRowRender: (record) => <Text>{record.body}</Text>,
              }}
              columns={columns}
              dataSource={notifications?.items.map((item, key) => {
                return {
                  ...item,
                  key,
                };
              })}
            />
            <ComposeMessageModal
              isOpened={isComposeMessageModalOpened}
              onClose={handleToggleModal}
            />
          </Card>
        </Layout>
      </Layout>
    </Layout>
  );
};
