import { useCallback, useEffect, useState } from "react";
import { API_ENDPOINTS, axiosApiInstance } from "../api";

export const useNotificationsApi = () => {
  const [notifications, setNotifications] = useState<{
    items: any[];
    meta: {
      currentPage: number;
      itemsCount: number;
      itemsPerPage: number;
      totalItems: number;
      totalPages: number;
    };
  } | null>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [isLoading, setLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const { data, status } = await axiosApiInstance.get(
        API_ENDPOINTS.notificationsList,
        {
          params: {
            limit,
            page,
          },
        }
      );
      if (status === 200) {
        setNotifications(data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [limit, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { notifications, isLoading, setPage, setLimit };
};
