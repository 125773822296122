import { constants } from "../constants";

export const getTokensFromLocalStorage = () => {
  const tokens = localStorage.getItem(constants.LOCALSTORAGE_TOKENS_ID);
  if (tokens) {
    const parsedTokens = JSON.parse(tokens);
    return parsedTokens;
  } else {
    return null;
  }
};

export const removeTokensFromLocalStorage = () =>
  localStorage.removeItem(constants.LOCALSTORAGE_TOKENS_ID);

export const setTokensToLocalStorage = (data: {
  access: string;
  refresh: string;
}) =>
  localStorage.setItem(constants.LOCALSTORAGE_TOKENS_ID, JSON.stringify(data));
