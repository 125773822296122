import * as S from "./styled";
import { Card, Button, Form, Input, Typography } from "antd";
import { Dispatch, SetStateAction } from "react";
import { API_ENDPOINTS, axiosApiInstance } from "../../api";
import { setTokensToLocalStorage } from "../../utils";

const { Title } = Typography;

export const AuthForm: React.FC<{
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
}> = ({ setLoggedIn }) => {
  const onFinish = async (values: any) => {
    const { data, status } = await axiosApiInstance.post(API_ENDPOINTS.login, {
      login: values.email,
      password: values.password,
    });

    if (status === 201) {
      setLoggedIn(true);
      setTokensToLocalStorage(data.tokens);
    }
  };
  const onFinishFailed = () => {};
  return (
    <S.Container>
      <Card>
        <Title level={2} style={{ textAlign: "center", marginBottom: 40 }}>
          Authorization
        </Title>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="E-mail"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your e-mail.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password." }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </S.Container>
  );
};
