import axios from "axios";
import { getTokensFromLocalStorage } from "../utils";

export const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosApiInstance.interceptors.request.use(
  (config) => {
    const tokens = getTokensFromLocalStorage();
    if (tokens && config.headers) {
      config.headers.Authorization = `Bearer ${tokens.access}`;
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosApiInstance.interceptors.response.use(
//   async (response) => {
//     return response;
//   },
//   async (err) => {
//     const originalConfig = err.config;
//     const tokens = localStorage.getItem("TOKENS");
//     if (tokens) {
//       const parsedTokens = JSON.parse(tokens);

//       const { status, data } = await axiosApiInstance.post(
//         API_ENDPOINTS.refreshTokens,
//         parsedTokens.refresh
//       );

//       if (status === 201) {
//         axiosApiInstance.defaults.headers.Authorization = `Bearer ${data.tokens.access}`;

//         return axiosApiInstance(originalConfig);
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export const API_ENDPOINTS = {
  login: "/auth/login",
  notificationsList: "/notifications/push/list",
  refreshTokens: "/auth/refresh",
  logout: "/auth/logout",
  sendPushNotificationsToAllUsers: "/notifications/push/all",
};
