import React, { useEffect, useState } from "react";
import { DashboardScreen } from "./containers/dashboard";
import { AuthForm } from "./containers/auth";
import { Spin } from "antd";
import { API_ENDPOINTS, axiosApiInstance } from "./api";
import "antd/dist/reset.css";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const refreshTokens = async () => {
    const tokens = localStorage.getItem("TOKENS");
    if (tokens) {
      try {
        const parsedTokens = JSON.parse(tokens);
        const { status, data } = await axiosApiInstance.post(
          API_ENDPOINTS.refreshTokens,
          { refreshToken: parsedTokens.refresh }
        );

        axiosApiInstance.defaults.headers.Authorization = `Bearer ${data.tokens.access}`;

        if (status === 201) {
          localStorage.setItem("TOKENS", JSON.stringify(data.tokens));

          setLoggedIn(true);
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshTokens();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin tip="Loading" size="large"></Spin>
      </div>
    );

  if (isLoggedIn) return <DashboardScreen setLoggedIn={setLoggedIn} />;
  else return <AuthForm setLoggedIn={setLoggedIn} />;
}

export default App;
