import { Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { PushNotificationMessageType } from "./typings";

export const columns: ColumnsType<PushNotificationMessageType> = [
  {
    title: "Headline",
    dataIndex: "title",
    key: "title",
    sorter: (a, b) => a.title.localeCompare(b.title),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Body",
    dataIndex: "body",
    key: "body",
    ellipsis: true,
  },
  {
    title: "CreatedAt",
    dataIndex: "createdAt",
    key: "createdAt",
    ellipsis: true,
    render: (_, record) =>
      new Date(record.createdAt).toLocaleDateString("en-US"),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "5%",
    render: (_, record) => (
      <Tag color={record.status === "SENT" ? "green" : "red"}>
        {record.status}
      </Tag>
    ),
  },
];
