import { Button, Form, Input, Modal, notification } from "antd";
import { API_ENDPOINTS, axiosApiInstance } from "../../api";

type ModalTypes = {
  isOpened: boolean;
  onClose: () => void;
};

export const ComposeMessageModal: React.FC<ModalTypes> = ({
  isOpened,
  onClose,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const onFinish = async (values: {
    headline: string;
    body: string;
    linkUrl: string;
    linkTitle: string;
  }) => {
    const dataToSend = {
      title: values.headline,
      body: values.body,
      data: { linkTitle: "", linkUrl: "" },
    };

    if (values.linkUrl) {
      dataToSend.data.linkTitle = values.linkTitle;
      dataToSend.data.linkUrl = values.linkUrl;
    }

    const { status } = await axiosApiInstance.post(
      API_ENDPOINTS.sendPushNotificationsToAllUsers,
      dataToSend
    );

    if (status === 201) {
      openNotification();
      onClose();
    } else {
      openNotification();
    }
  };

  const openNotification = () => {
    api.success({
      message: "Success",
      description: "Message sent",
    });
  };

  const onFinishFailed = () => {};
  return (
    <>
      {contextHolder}
      <Modal
        open={isOpened}
        onCancel={onClose}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
      >
        <Form
          layout="vertical"
          style={{ marginTop: 40 }}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Headline"
            name="headline"
            rules={[
              {
                required: true,
                message: "Headline is required field.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Body"
            name="body"
            rules={[{ required: true, message: "Body is required field." }]}
          >
            <Input.TextArea rows={10} />
          </Form.Item>

          <Form.Item
            label="Link Title"
            name="linkTitle"
            rules={[{ required: false, type: "string" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Link URL"
            name="linkUrl"
            rules={[{ required: false, type: "url" }]}
          >
            <Input />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </Form>
      </Modal>
    </>
  );
};
